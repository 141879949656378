<script setup lang="ts">
import {CContainer} from "@coreui/vue";

const { t } = useI18n();

useSeoMeta({
  description: t('head.description'),
  ogDescription: t('head.description'),
  keywords: t('head.keywords'),
  author: 'VVeb Developer',
  ogType: 'website',
  ogLocale: 'ru_RU',
  ogImage: absoluteUrl(`/images/belarusborder.png`),
});
</script>

<template>
  <div>
    <CContainer class="px-4 py-4">
      <AppNavbar />
      <slot />
    </CContainer>
    <AppToaster />
  </div>
</template>