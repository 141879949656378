<script setup lang="ts">
import {CTooltip} from "@coreui/vue";
import {computed} from "vue";
import Item from "~/dto/CarQueueItem";

const props = defineProps({
  item: {
    type: Object as () => Item,
    required: true
  },
  class: {
    type: String,
    default: null,
  },
  iconSize: {
    type: String,
    default: 'icon-lg',
  }
});

const waitingDurationValue = computed<{h: number, m: number} | null>(() => {
  const duration = waitingDuration(props.item);

  if (!duration) {
    return null;
  }

  return {
    h: parseInt(duration.hours, 10),
    m: parseInt(duration.minutes, 10),
  };
});
</script>

<template>
  <CTooltip
    v-if="waitingDurationValue"
    :content="$t('navbar.tracking.queue_time_title', waitingDurationValue)"
  >
    <template #toggler="{ on }">
      <div
        :class="props.class"
        v-on="on"
      >
        <i
          class="cil-clock icon me-1"
          :class="iconSize"
        /> {{ `${waitingDurationValue.h}:${waitingDurationValue.m.toString().padStart(2, '0')}` }}
      </div>
    </template>
  </CTooltip>
</template>