<script setup lang="ts">
import {CNavbar, CContainer, CTooltip} from "@coreui/vue";

const { telegram: { bot_link } } = useAppConfig();
</script>

<template>
  <CNavbar
    color-scheme="light"
    class="bg-light mb-4"
  >
    <CContainer
      fluid
      class="flex-column flex-md-row align-items-stretch align-items-md-center"
    >
      <div class="d-flex">
        <NuxtLink
          to="/"
          class="navbar-brand"
        >
          <img
            src="/images/navbar.png"
            height="32"
            class="d-inline-block align-top"
            :alt="$t('navbar.main')"
          > {{ $t('navbar.main') }}
        </NuxtLink>
        <CTooltip
          v-if="bot_link"
          :content="$t('navbar.telegram_bot_link_tooltip')"
        >
          <template #toggler="{ on }">
            <a
              class="icon-link link-dark link-underline link-underline-opacity-0 ms-auto"
              :href="bot_link"
              v-on="on"
            >
              <i class="icon icon-xl cib-telegram-plane" />
            </a>
          </template>
        </CTooltip>
      </div>
      <div class="d-flex flex-column flex-md-row">
        <ClientOnly>
          <NavbarTracking class="d-flex " />
          <template #fallback>
            <IconLoading
              :size="24"
              class="success m-auto"
            />
          </template>
        </ClientOnly>
      </div>
    </CContainer>
  </CNavbar>
</template>
