<script setup lang="ts">
import {CTooltip} from "@coreui/vue";
import Item from "~/dto/CarQueueItem";

const { t } = useI18n();

const props = defineProps({
  item: {
    type: Object as () => Item,
    required: true,
  },
  class: {
    type: String,
    default: null,
  }
});
defineEmits(['openModal']);
</script>

<template>
  <div
    v-if="item.isArrived"
    :class="props.class"
  >
    <CTooltip :content="t('navbar.tracking.queue_position', {n: item.callingOrder})">
      <template #toggler="{ on }">
        <div v-on="on">
          <i class="cil-traffic icon icon-lg me-1" />
          <span class="d-none d-sm-inline-block">{{ t('navbar.tracking.in_queue', {n: item.callingOrder}) }}</span>
          <span class="d-sm-none">{{ t('navbar.tracking.in_queue_n', {n: item.callingOrder}) }}</span>
        </div>
      </template>
    </CTooltip>
    <button
      class="expand-btn btn btn-outline-dark btn-sm"
      @click="$emit('openModal')"
    >
      <i class="cil-move icon icon-sm" />
    </button>
  </div>
  <div
    v-else
    :class="props.class"
  >
    <i class="cil-traffic icon icon-lg me-1" />
    <span class="d-none d-sm-inline-block">{{ t('navbar.tracking.queue_called') }}</span>
    <span class="d-sm-none">{{ t('navbar.tracking.queue_called_n') }}</span>
    <button
      class="expand-btn btn btn-outline-dark btn-sm"
      @click="$emit('openModal')"
    >
      <i class="cil-move icon icon-sm" />
    </button>
  </div>
</template>

<style scoped>
.expand-btn {
  margin-left: 4px;
  --cui-btn-padding-y: .0rem;
  --cui-btn-padding-x: .25rem;
}
</style>