<script setup lang="ts">
import {CTooltip} from "@coreui/vue";
import Checkpoint from "~/dto/Checkpoint";

const { t } = useI18n();

const props = defineProps({
  checkpoint: {
    type: Object as () => Checkpoint,
    required: true
  },
  class: {
    type: String,
    default: null,
  },
  iconSize: {
    type: String,
    default: 'icon-lg',
  }
});
</script>

<template>
  <CTooltip :content="t('navbar.tracking.checkpoint', {name: checkpoint.name})">
    <template #toggler="{ on }">
      <div
        class="d-flex align-items-center"
        :class="props.class"
        v-on="on"
      >
        <i
          class="cil-location-pin icon"
          :class="iconSize"
        /> {{ checkpoint.name }}
      </div>
    </template>
  </CTooltip>
</template>
