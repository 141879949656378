<script setup lang="ts">
import {CTooltip} from "@coreui/vue";
import Item from "~/dto/CarQueueItem";

const props = defineProps({
  item: {
    type: Object as () => Item,
    required: true
  },
  class: {
    type: String,
    default: null,
  }
});

defineEmits(['forget']);
</script>

<template>
  <div :class="props.class">
    <CTooltip :content="$t('navbar.tracking.car_plate_title', {number: item.registrationNumber})">
      <template #toggler="{ on }">
        <div v-on="on">
          <i class="cil-car icon icon-lg me-1" /><strong>{{ item.registrationNumber }}</strong>
        </div>
      </template>
    </CTooltip>
    <a
      class="forget-btn btn btn-outline-dark btn-sm"
      @click="$emit('forget')"
    >
      <span class="cil-x icon icon-sm" />
    </a>
  </div>
</template>

<style scoped>
.forget-btn {
  margin-left: 4px;
  margin-top: -2px;
  --cui-btn-padding-y: .0rem;
  --cui-btn-padding-x: .25rem;
}
</style>