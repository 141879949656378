<script setup lang="ts">
import {CFormInput, CButton} from "@coreui/vue";

const props = defineProps({
  class: {
    type: String,
    default: null,
  }
});

const store = useTrackingStore();
const toasts = useToastsStore();
const {loading, registrationNumber, queueItem} = storeToRefs(store);

const registrationNumberValue = ref<string>(registrationNumber.value ? registrationNumber.value : '');
const isFinding = computed(() => !registrationNumber.value && loading.value);
const isFindDisabled = computed(() => registrationNumberValue.value.length <= 2 || isFinding.value);
const isModalOpen = ref<boolean>(false);

const { t } = useI18n();
const analytics = useAnalytics();

watch(queueItem, (item) => {
  if (!item) {
    isModalOpen.value = false;
  }
});

const find = () => {
  analytics.trackingUsing(registrationNumberValue.value);
  store.startTracking(registrationNumberValue.value)
      .then(item => {
        if (item) {
          analytics.trackingFound(registrationNumberValue.value);
        } else {
          toasts.addInfo(t('navbar.tracking.no_car_found'));
          analytics.trackingNotFound(registrationNumberValue.value);
        }
      })
      .catch((error) => {
        analytics.trackingFailed(registrationNumberValue.value);
        toasts.addError(t('error_occurred_try_later'));
        captureException(error);
      });
};

const openModal = () => {
  isModalOpen.value = true;
  analytics.trackingExpanded(registrationNumberValue.value);
};

onMounted(() => {
  if (store.hasTrackingSession()) {
    store.resumeTracking().catch(store.forgetTrackingSession);
  }
});
</script>

<template>
  <div
    v-if="queueItem"
    :class="props.class"
    class="justify-content-end"
  >
    <NavbarTrackingItem
      :item="queueItem"
      class="d-flex align-items-center me-2"
      @forget="store.stopTracking()"
    />
    <NavbarTrackingPosition
      :item="queueItem"
      class="d-flex align-items-center me-2"
      @open-modal="openModal"
    />
    <NavbarTrackingWaitingTime
      :item="queueItem"
      class="waiting-time d-flex align-items-center me-1"
    />
    <NavbarTrackingCheckpoint
      :checkpoint="queueItem.checkpoint!"
      class="d-none align-items-center d-xxl-flex d-xl-flex d-lg-flex"
    />
  </div>
  <div
    v-else-if="store.hasTrackingSession()"
    :class="props.class"
  >
    <IconLoading
      :size="24"
      class="success m-auto"
    />
  </div>
  <div
    v-else
    :class="props.class"
  >
    <CFormInput
      v-model="registrationNumberValue"
      type="search"
      class="me-2"
      maxlength="10"
      :placeholder="$t('navbar.tracking.car_plate')"
      :disabled="loading"
      @keyup.enter="find"
    />
    <CButton
      type="button"
      color="dark"
      variant="outline"
      class="d-inline-flex"
      :disabled="isFindDisabled"
      @click="find"
    >
      <IconLoading
        v-if="isFinding"
        :size="24"
        class="dark"
      />
      {{ $t('navbar.tracking.find') }}
    </CButton>
  </div>
  <NavbarTrackingModal
    v-if="queueItem"
    :open="isModalOpen"
    :item="queueItem"
    @close="isModalOpen = false"
  />
</template>

<style scoped>
@media (max-width: 400px) {
  :deep(.waiting-time) {
    display: none !important;
  }
}
</style>